import { useGlobalSearch } from "@ludens-reklame/rubics-v3-react/search/hooks";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { GlobalSearchResult } from "@ludens-reklame/rubics-v3-sdk/search/types";
import { RichText } from "../../internal-components/richText/RichText.js";
import { Busy } from "../../internal-components/busy/busy.js";

interface Props {}

const SearchResults: React.FC<Props> = () => {
  const store = useStore();
  const query = `${store.context.query.q}`;
  const { results, loading } = useGlobalSearch(query);

  return (
    <div className="search-results mw-small hs vs-xl">
      <h1 className="h4">Søkeresultater for "{query}"</h1>
      <Busy busy={loading}>
        {!loading && results.length === 0 && (
          <p className="b3">Fant ingen resultater for "{query}".</p>
        )}
        <ul className="results">
          {results.map((item) => {
            return <Result key={item._id} {...item} />;
          })}
        </ul>
      </Busy>
    </div>
  );
};

const Result: React.FC<GlobalSearchResult> = ({
  _id,
  type,
  url,
  label,
  text,
}) => {
  /**
   * We don't want to show products in the search results yet,
   * so we are returning null if the type is "product".
   */
  if (type === "product") return null;

  return (
    <li key={_id} className="result">
      <a href={url}>
        <h2 className="h6">{label}</h2>
        {text && <RichText html={text} className="b1" />}
      </a>
    </li>
  );
};

export default SearchResults;
